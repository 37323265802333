jQuery(document).ready(function($){
	$('.menu-item-31').hover(
		function(){
			$(this).find('.sub-menu').addClass('open');
		}, function(){
			$(this).find('.sub-menu').removeClass('open');
		}
	);
}); 

jQuery(document).ready(function($){
	$('#bg_imgs').slick({
		dots: false,
		arrows: false,
	  	infinite: true,
	  	autoplay: true,
	  	autoplaySpeed: 6000,
	  	speed: 500,
	  	fade: true,
	  	cssEase: 'linear'
	});
});