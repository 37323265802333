jQuery(document).ready(function($){
	$('.modal_link').click(function(){
		$('#modal_overlay').addClass('open');
		$('#quote_modal').addClass('open');
		$('body').css('overflow', 'hidden');
	});

	$('#modal_overlay').click(function(){
		$('#modal_overlay').removeClass('open');
		$('#quote_modal').removeClass('open');
		$('body').css('overflow', '');
	});

	$('#quote_modal .close').click(function(){
		$('#modal_overlay').removeClass('open');
		$('#quote_modal').removeClass('open');
		$('body').css('overflow', '');
	});
});

jQuery(document).ready(function($){
	$('.contact_modal_link').click(function(){
		$('#modal_overlay').addClass('open');
		$('#contact_modal').addClass('open');
		$('body').css('overflow', 'hidden');
	});

	$('#modal_overlay').click(function(){
		$('#modal_overlay').removeClass('open');
		$('#contact_modal').removeClass('open');
		$('body').css('overflow', '');
	});

	$('#contact_modal .close').click(function(){
		$('#modal_overlay').removeClass('open');
		$('#contact_modal').removeClass('open');
		$('body').css('overflow', '');
	});
});